<template>
	<div class=" ">
		<el-card>
			<div slot="header" class="card_title">
				<span>新增推送信息</span>
			</div>
			<div class="add_main hidden_scroll">
				<div class="margin_top_20">
					<el-form
						label-position="right"
						label-width="120px"
						:model="formData"
						:rules="rule"
						ref="formData"
					>
						<el-form-item label="标题" prop="title" class="margin_top_20">
							<el-input v-model="formData.title" placeholder="标题"></el-input>
						</el-form-item>
						<el-form-item label="描述" prop="desc">
							<el-input v-model="formData.desc" placeholder=""></el-input>
						</el-form-item>

						<el-form-item label="文章id" prop="object_id">
							<el-input v-model="formData.object_id" placeholder=""></el-input>
						</el-form-item>
						<el-form-item>
							<div class="flex justify_content_c align_items_c">
								<el-button
									type="primary"
									class="custom_btn_width_100"
									@click="submit('formData')"
									>保存</el-button
								>
								<el-button
									class="custom_btn_width_100"
									@click="$router.push({ path: '/limitsauthority/personnel' })"
									>返回</el-button
								>
							</div>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
import { addPush } from "@/api/user";
import {
	validateEqNotNull,
	validateNull,
	validatePhone,
} from "@/utils/validate";
export default {
	name: "",
	data() {
		return {
			value: "",
			formData: {
				title: "",
				object_id: 0,
				type: 1,
				desc: "",
			},
			rule: {
				title: [{ validator: validateNull, trigger: "blur", required: true }],
				desc: [{ validator: validateNull, trigger: "blur", required: true }],
			},
		};
	},
	created() {},
	methods: {
		// 新增资讯提交
		submit(formName) {
			const that = this;
			that.formData.object_id = Number(that.formData.object_id);
			addPush(that.formData)
				.then((res) => {
					if (res.code == 200) {
						that.$router.push({ path: "/information/push" });
					} else {
					}
				})
				.catch((error) => {});
		},
	},
};
</script>

<style scoped>
.main {
	width: calc(100% - 40px);
}

.add_main {
	width: 70%;
	height: 81vh;
	overflow-y: scroll;
	margin: 0 auto;
}
</style>
